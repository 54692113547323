import { reduxStore } from 'services/configureStore';
import { COGNITO_CONFIG, REFRESH_TOKEN, TOKEN } from 'constants/localStorageConstants';
import { setToken } from 'reducers/userAuthSlice';
import axios from 'axios';
import {
	checkRedirectURIAllowed,
	createMobileRedirectUrl
} from 'components/authentication/authenticationUtils';

let myHeaders = new Headers();
myHeaders.set('Cache-Control', 'no-store');
let urlParameters = new URLSearchParams(window.location.search);

//Generate a Random String
const getRandomString = () => {
	const randomItems = new Uint32Array(28);
	crypto.getRandomValues(randomItems);
	const binaryStringItems = randomItems.map(dec => `0${dec.toString(16).slice(-2)}`);
	return binaryStringItems.reduce((accumulator, item) => `${accumulator}${item}`, '');
};

//Encrypt a String with SHA256
const encryptStringWithSHA256 = async string_ => {
	const PROTOCOL = 'SHA-256';
	const textEncoder = new TextEncoder();
	const encodedData = textEncoder.encode(string_);
	return crypto.subtle.digest(PROTOCOL, encodedData);
};

//Convert Hash to Base64-URL
const hashToBase64url = arrayBuffer => {
	const items = new Uint8Array(arrayBuffer);
	const stringifiedArrayHash = items.reduce(
		(accumulator, index) => `${accumulator}${String.fromCharCode(index)}`,
		''
	);
	const decodedHash = btoa(stringifiedArrayHash);

	const base64URL = decodedHash.replaceAll('+', '-').replaceAll('/', '_').replace(/=+$/, '');
	return base64URL;
};

export const authorizeCognito = async ({ client_id, redirect_uri, host }) => {
	// Create random "state"
	let state = getRandomString();
	sessionStorage.setItem('pkce_state', state);

	// Create PKCE code verifier
	let code_verifier = getRandomString();
	sessionStorage.setItem('code_verifier', code_verifier);

	// Create code challenge
	let arrayHash = await encryptStringWithSHA256(code_verifier);
	let code_challenge = hashToBase64url(arrayHash);
	sessionStorage.setItem('code_challenge', code_challenge);
	// Redirect user-agent to /authorize endpoint
	// Show login form
	const redirectUrl = `${host}/oauth2/authorize?response_type=code&state=${state}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid&code_challenge_method=S256&code_challenge=${code_challenge}`;
	window.location.assign(redirectUrl);
};

const getCognitoTokens = async (code, { client_id, redirect_uri, success_redirect_uri, host }) => {
	// Verify state matches
	let state = urlParameters.get('state');

	if (sessionStorage.getItem('pkce_state') === state) {
		// Fetch OAuth2 tokens from Cognito
		let code_verifier = sessionStorage.getItem('code_verifier');
		try {
			const url = `${host}/oauth2/token?grant_type=authorization_code&client_id=${client_id}&code_verifier=${code_verifier}&redirect_uri=${redirect_uri}&code=${code}`;
			const res = await axios.post(
				url,
				{},
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				}
			);
			const { id_token, refresh_token, access_token } = res.data;

			if (success_redirect_uri && checkRedirectURIAllowed(success_redirect_uri)) {
				//Mobile flow
				const mobileRedirect = createMobileRedirectUrl({
					host,
					client_id,
					success_redirect_uri,
					refresh_token,
					id_token,
					access_token
				});
				//Send Mobile all of the info in the url
				window.location.assign(mobileRedirect);
			} else {
				//Web flow
				localStorage.setItem(TOKEN, id_token);
				localStorage.setItem(REFRESH_TOKEN, refresh_token);
				reduxStore.dispatch(setToken(id_token));
				return res.data;
			}
		} catch (error) {
			console.error(`Air Control had the following errors: ${error}`);
		}
	}
};

export const refreshCognito = async () => {
	// Fetch OAuth2 tokens from Cognito
	const refreshToken = localStorage.getItem(REFRESH_TOKEN);
	const config = JSON.parse(localStorage.getItem(COGNITO_CONFIG));
	const { client_id, redirect_uri, host } = config;

	try {
		const url = `${host}/oauth2/token?grant_type=refresh_token&client_id=${client_id}&redirect_uri=${redirect_uri}&refresh_token=${refreshToken}`;
		const res = await axios.post(
			url,
			{},
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}
		);
		localStorage.setItem(TOKEN, res.data.id_token);
		reduxStore.dispatch(setToken(res.data.id_token));
		return res.data;
	} catch (error) {
		const origin = window.location.origin;
		window.location.assign(`${origin}/logout`);
		console.error(`Air Control had the following errors: ${error}`);
	}
};

export const handleAwsCognito = async config => {
	let code = urlParameters.get('code');
	if (code === null) {
		authorizeCognito(config);
	} else {
		getCognitoTokens(code, config);
	}
};
