import { BASE_JWT_URL } from 'constants/environmentVariables';
import { handleErrors } from 'actions/errors';
import axiosInstance from 'services/axiosConfig';
import {
	setUser,
	currentAccountIdSelector,
	setUserDetailsLoading,
	setUserDetailsLoaded
} from 'reducers/userAuthSlice';

export const fetchMeDetails = () => async (dispatch, getState) => {
	const currentAccountId = currentAccountIdSelector(getState());
	dispatch(setUserDetailsLoading(true));
	try {
		const url = `${BASE_JWT_URL}/v1/account/${currentAccountId}/profile?appends[]=role`;
		const response = await axiosInstance.get(url);
		//Overwrite the user in redux store with more data than what was fetched by the /whoami endpoint - most importantly the user's roles on this account
		const userData = response?.data.data;
		dispatch(setUser(userData));
		dispatch(setUserDetailsLoading(false));
		dispatch(setUserDetailsLoaded(true));
		return userData;
	} catch (error) {
		dispatch(setUserDetailsLoading(false));
		handleErrors({ errors: error, dispatch });
	}
};

export const deleteAccount = () => async (dispatch, getState) => {
	const currentAccountId = currentAccountIdSelector(getState());
	try {
		const url = `${BASE_JWT_URL}/account/${currentAccountId}/delete`;
		const response = await axiosInstance.post(url);
		return response.data;
	} catch (error) {
		handleErrors({ errors: error, dispatch });
	}
};

export const registrationsPreCheck = payload => async (dispatch, getState) => {
	try {
		const url = `${BASE_JWT_URL}/v1/registration-precheck`;
		const response = await axiosInstance.post(url, payload);
		return response.data.data.data;
	} catch (error) {
		handleErrors({ errors: error, dispatch });
	}
};

export const fetchCognitoConfig = payload => async (dispatch, getState) => {
	try {
		const url = `${BASE_JWT_URL}/v1/authentication-provider`;
		const response = await axiosInstance.post(url, { email: payload });
		return response.data.data;
	} catch (error) {
		handleErrors({ errors: error, dispatch });
	}
};

export const logout = payload => async (dispatch, getState) => {
	const currentAccountId = currentAccountIdSelector(getState());
	try {
		const url = `${BASE_JWT_URL}/v1/account/${currentAccountId}/logout`;
		const res = await axiosInstance.post(url, { refresh_token: payload });
		return res;
	} catch (error) {
		handleErrors({ errors: error, dispatch });
	}
};
