import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	activitiesList: [],
	editModeId: false
};

const activitiesSlice = createSlice({
	name: 'activities',
	initialState,
	reducers: {
		setActivitiesList(state, action) {
			state.activitiesList = action.payload;
		},
		setEditModeId(state, action) {
			state.editModeId = action.payload;
		},
		resetActivitiesSlice() {
			return initialState;
		}
	}
});

export const { setActivitiesList, setEditModeId, resetActivitiesSlice } = activitiesSlice.actions;

export default activitiesSlice.reducer;

export const activitiesListSelector = state => state.activities.activitiesList;
export const editModeIdSelector = state => state.activities.editModeId;
