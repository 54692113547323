import {
	$backgroundOffWhite,
	$black,
	$btnPrimary,
	$danger,
	$gray100,
	$loginDarkGray,
	$loginLightGray,
	$primary,
	$success,
	$tan,
	$white
} from 'constants/styles/_colors';
import { blue, grey } from '@mui/material/colors';

// viewport width
export const sideNavWidth = 66;
export const subNavWidth = 250;

export const defaultStyleLayer = 'light';
export const rightPanelContainerWidth = 660;
export const targetViewportWidth = 1024;
export const minMapViewport = targetViewportWidth - (sideNavWidth + rightPanelContainerWidth);

// button Primary style
export const btnPrimary = {
	color: $white,
	background: $btnPrimary
};

// Hover effects
export const hoverRiseShadow = {
	border: `none`,
	boxShadow:
		'0px 3px 3px 0px rgba(0,0,0,0.4), 0px 8px 11px 0px rgba(0,0,0,0.04), 0px 4px 7px -27px rgba(0,0,0,0.12)'
};

export const elevation14 = {
	boxShadow:
		'0px 20px 28px -22px rgba(0,0,0,0.2), 0px 76px 19px -5px rgba(0,0,0,0.01), 0px 22px 21px 0px rgba(0,0,0,0.1)'
	// '0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12)'
};

export const elevation4 = {
	boxShadow:
		'0px 1px 5px -1px rgba(0,0,0,0.1), 0px 3px 3px -2px rgba(0,0,0,0.04), 0px 3px 4px 0px rgba(0,0,0,0.12)'
};

export const searchInputStyle = {
	notActive: { margin: '0px' },
	isActive: {
		borderRadius: '6px 6px 0px 0px',
		borderTop: `solid 1px ${$gray100}`,
		borderRight: `solid 1px ${$gray100}`,
		borderLeft: `solid 1px ${$gray100}`,
		margin: '0px -15px',
		padding: '0px 14px',
		...elevation14
	}
};

export const stepIcon = {
	fontSize: '18px',
	border: `3px solid ${$success}`,
	borderRadius: '50%',
	textAlign: 'center',
	height: '38px',
	width: '38px',
	lineHeight: '33px',
	fontWeight: 700
};

export const createEditDrawerStyles = {
	tabRoot: {
		textTransform: 'none'
	},
	swipeableStyle: {
		flexGrow: 1,
		overflowY: 'auto'
	},
	swipeableContainerStyle: {
		height: '100%'
	},
	bottom: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		flex: 1
	},
	drawerContainer: {
		display: 'flex',
		flexFlow: 'column',
		height: '100%',
		overflowY: 'hidden'
	},
	drawerFixedTop: {
		flex: '0 1 auto'
	},
	drawerScrollableBottom: {
		flex: '1 1 auto',
		overflowY: 'auto'
	},
	topDetails: {
		marginTop: '20px',
		marginBottom: '40px'
	},
	listItemsPresent: {
		marginTop: '20px',
		padding: '0px'
	},
	noListItemsPresent: {
		padding: '0px'
	},
	statusPlaceholder: {
		height: '27px'
	},
	buttonsSection: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginBottom: '20px'
	},
	mobileButtonsSection: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '20px'
	},
	editModeActions: {
		display: 'flex',
		alignItems: 'center'
	},
	exitEditMode: {
		textTransform: 'none',
		marginRight: '10px'
	},
	addBtnContainer: {
		position: 'relative'
	},
	requiredNote: {
		position: 'absolute',
		bottom: '-20px',
		width: '100%',
		textAlign: 'center',
		fontSize: '12px',
		fontStyle: 'italic'
	},
	statusSelector: {
		marginRight: '10px'
	},
	fullWidth: {
		width: '100%'
	},
	fullHeightPanel: {
		height: '100%'
	},
	fullHeight: {
		height: '100%'
	},
	title: {
		fontWeight: 700,
		marginBottom: '20px'
	},
	titleNoMargin: {
		fontWeight: 700
	},
	section: {
		marginBottom: '40px'
	},
	checkboxLabel: {
		fontSize: '18px',
		lineHeight: '42px',
		verticalAlign: 'middle'
	},
	card: {
		padding: '20px'
	},
	headerContainer: {
		display: 'grid',
		gridTemplateColumns: '5fr 2fr',
		gap: '40px',
		alignItems: 'center'
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	columnsContainer: {
		height: '100%',
		display: 'flex',
		gap: '0 40px'
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		width: 'calc(50% - 20px)'
	},
	input: {
		'&::placeholder': {
			fontSize: '24px',
			lineHeight: '26px',
			color: $tan
		}
	},
	field: {
		marginTop: '20px'
	},
	fieldContainer: {
		margin: '20px 0'
	}
};

export const actionHeaderStyles = {
	actionsHeader: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		minHeight: '81px'
	},
	top: {
		display: 'flex',
		// flex: '1 1 0%',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: '20px',
		gridTemplateColumns: 'auto 1fr auto',
		gridGap: '15px'
	},
	mobileTop: {
		display: 'flex',
		flex: '1 1 0%',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: '10px',
		gridGap: '15px'
	},
	titleAndNavButton: {
		flex: '1 1 auto',
		flexGrow: 2
	},
	bottom: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: '20px'
	},
	mainButton: {
		float: 'right',
		backgroundColor: $white,
		marginRight: '15px',
		width: 'fit-content'
	},
	searchInput: {
		width: '250px'
	},
	resetButton: {
		marginLeft: '75px',
		border: `solid 1px ${$black}`,
		borderRadius: 0
	},
	zoomToSelectedButton: {
		textTransform: 'none'
	},
	buttonRow: {
		display: 'flex',
		gap: '0 10px'
	}
};

export const filterStyles = {
	filterButton: {
		textTransform: 'none',
		width: '100%'
	},
	popoverContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	popoverContent: {
		padding: '20px',
		paddingBottom: '20px'
	},
	clearIcon: {
		fontSize: '12px'
	},
	mobileAppliedFilters: {
		marginBottom: '20px'
	},
	cardActions: {
		paddingTop: '0 !important'
	},
	rangeButton: {
		'&.Mui-disabled': {
			color: $primary
		}
	},
	noOptions: {
		padding: '20px'
	}
};

export const fabStyles = {
	fabContainer: {
		background: $white,
		...elevation4
	}
};

export const authenticationPagesStyles = theme => ({
	main: {
		height: '100%',
		width: '100%',
		overflow: 'auto',
		padding: '40px',
		backgroundColor: $black,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'space-between',
		[theme.breakpoints.down('lg')]: {
			alignItems: 'center',
			padding: '30px'
		}
	},
	headerContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('lg')]: {
			flexDirection: 'column',
			gap: '20px',
			marginBottom: '20px'
		}
	},
	logo: {
		cursor: 'pointer'
	},
	airControlLogo: {
		marginBottom: '20px',
		maxWidth: '400px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	outlineButton: {
		color: $white,
		border: `1px solid ${$white}`,
		fontWeight: 'bold',
		height: '50px',
		width: '350px',
		maxWidth: '100%',
		'&:hover': {
			border: `1px solid ${$white} !important`,
			backgroundColor: `rgba(255, 255, 255, 0.2) !important`
		}
	},
	topSupportButton: {
		[theme.breakpoints.down('lg')]: {
			display: 'none'
		}
	},
	bottomSupportButton: {
		marginTop: '20px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	mainContainer: {
		width: '1200px',
		margin: '50px auto',
		maxWidth: '100%',
		[theme.breakpoints.down('lg')]: {
			margin: '0 auto 50px'
		}
	},
	leftContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	leftContainerText: {
		color: $white,
		fontWeight: 800,
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('lg')]: {
			alignItems: 'center'
		}
	},
	rightContainer: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('lg')]: {
			alignItems: 'center'
		}
	},
	outlineContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '50px',
		width: '500px',
		border: `1px solid ${$loginDarkGray}`,
		borderRadius: '4px',
		[theme.breakpoints.down('lg')]: {
			padding: '20px',
			width: '100%',
			maxWidth: '500px'
		}
	},
	inviteName: {
		fontSize: '30px'
	},
	accountName: {
		fontSize: '30px',
		fontWeight: 'bold',
		color: $primary,
		marginRight: '10px',
		marginLeft: '10px'
	},
	signIn: {
		fontSize: '50px',
		marginBottom: '15px',
		color: $white,
		[theme.breakpoints.down('lg')]: {
			display: 'none'
		}
	},
	belowAirControl: {
		marginTop: '40px',
		'& > div:first-child': {
			marginBottom: '10px'
		},
		[theme.breakpoints.down('lg')]: {
			display: 'none'
		}
	},
	actionLink: {
		fontWeight: 'bold',
		cursor: 'pointer',
		color: $primary
	},
	belowSignIn: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingLeft: '20px',
		paddingRight: '20px',
		width: '100%',
		maxWidth: '700px',
		'& .MuiTypography-root': {
			color: $white,
			fontWeight: 'bold',
			marginTop: '30px'
		},
		[theme.breakpoints.up('lg')]: {
			display: 'none'
		}
	},
	formTitle: {
		fontSize: '30px',
		marginBottom: '30px',
		color: $white
	},
	textFieldNoMargin: {
		backgroundColor: $white,
		borderRadius: '3px'
	},
	textField: {
		backgroundColor: $white,
		borderRadius: '3px',
		marginTop: '30px'
	},
	authCopy: {
		lineHeight: '1.5em',
		width: '500px',
		maxWidth: '100%',
		fontSize: '18px',
		fontWeight: 'bold',
		marginTop: '30px',
		[theme.breakpoints.down('lg')]: {
			display: 'none'
		}
	},
	button: {
		marginTop: '30px',
		height: '50px',
		'&.Mui-disabled': {
			backgroundColor: `${$loginLightGray} !important`,
			color: $loginDarkGray
		}
	},
	error: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: $danger,
		color: $white,
		padding: '5px',
		width: '100%',
		maxWidth: '500px',
		marginBottom: '5px',
		marginTop: '5px'
	},
	appLinks: {
		display: 'grid',
		gridGap: '40px',
		gridTemplateColumns: '1fr 1fr'
	},
	divider: {
		borderColor: $loginDarkGray,
		marginTop: '30px',
		marginBottom: '30px',
		width: '100%'
	},
	download: {
		fontSize: '20px',
		marginTop: '20px',
		marginBottom: '20px',
		textAlign: 'center'
	},
	forgotPassword: {
		alignSelf: 'flex-end',
		marginTop: '20px',
		cursor: 'pointer',
		'&.Mui-disabled': {
			color: `${$loginDarkGray} !important`
		}
	},
	termsCheckbox: {
		marginTop: '30px',
		'& .MuiCheckbox-root': {
			color: $white
		},
		'& .Mui-checked': {
			color: $primary
		},
		'& .Mui-disabled': {
			color: $loginLightGray
		},
		'& .MuiFormControlLabel-label': {
			color: `${$white} !important`
		}
	},
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center',
		color: $white
	},
	or: {
		marginTop: '30px',
		color: $loginDarkGray,
		'&::before': {
			borderColor: $loginDarkGray
		},
		'&::after': {
			borderColor: $loginDarkGray
		}
	}
});

export const viewDrawerStyles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	bottom: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1
	},
	tabRoot: {
		textTransform: 'none'
	},
	fullHeightPanel: {
		height: '100%'
	},
	swipeableStyle: {
		flexGrow: 1,
		overflowY: 'auto'
	},
	swipeableContainerStyle: {
		height: '100%'
	},
	section: {
		marginBottom: '40px'
	},
	card: {
		flexShrink: 0,
		padding: '0 40px 0 20px'
	},
	cardActions: {
		justifyContent: 'flex-end'
	}
};

export const dataGridStyle = {
	backgroundColor: $white,
	border: 'none !important',
	'& .MuiDataGrid-columnHeader': {
		justifyContent: 'flex-end',
		'&:focus-within': {
			outline: 'none !important'
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontSize: '15px',
			fontWeight: 600,
			color: grey[800]
		}
	},
	'& .MuiDataGrid-cell': {
		fontSize: '15px',
		'&:focus-within': {
			outline: 'none !important'
		},
		display: 'flex',
		alignItems: 'center'
	},
	' & .MuiDataGrid-pinnedColumns': {
		backgroundColor: $backgroundOffWhite
	},
	' & .MuiDataGrid-pinnedColumnHeaders': {
		backgroundColor: $backgroundOffWhite
	},
	'& .no-top-border': {
		borderBottom: 'none !important'
	},
	'& .no-bottom-border': {
		borderTop: 'none !important'
	},
	'& .selected-row': {
		backgroundColor: `${blue[50]} !important`
	},
	'& .sticky-row': {
		position: 'sticky',
		bottom: 0,
		backgroundColor: $white,
		borderTop: '1px solid rgba(224, 224, 224, 1)'
	},
	'& .MuiDataGrid-pinnedRows > .MuiDataGrid-row': {
		backgroundColor: `${$primary} !important`, // Example styling for pinned rows
		color: 'white',
		'&:hover': {
			cursor: 'default'
		}
	}
};
