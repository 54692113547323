import {
	COGNITO_CONFIG,
	CURRENT_ACCOUNT_ID,
	INTENDED_LOCATION,
	RECENT_SEARCH,
	REFRESH_TOKEN,
	SEARCH_FILTERS,
	SELECTED_LANGUAGE,
	SELECTED_MAP_LAYERS,
	SELECTED_MAP_STYLE,
	TABLE_SETTINGS,
	TOKEN,
	LIVE_TRAFFIC_COLUMNS,
	SELECTED_SEARCH_TYPE,
	AIRSPACE_MAP_BOUNDS,
	AIRSPACE_MAP_PIN
} from 'constants/localStorageConstants';
import { setAmplifyError } from 'reducers/userAuthSlice';
import { handleErrors } from 'actions/errors';
import { Amplify, Auth, Hub } from 'aws-amplify';
import {
	convertCognitoError,
	createAmplifyConfig,
	createCognitoConfig
} from 'components/authentication/authenticationUtils';
import { addSnackbarAlerts } from 'reducers/globalSlice';
import { stringifyQueryObj } from 'utils/urlHelpers';
import { t } from 'i18next';
import { fetchCognitoConfig, registrationsPreCheck } from 'apis/authentication.api';

export const isAuth = () => (dispatch, getState) => {
	return getState().userAuth.token;
};

export const clearLocalStorageOnLogout = (localStorage = window.localStorage) => {
	const cognitoItemKeys = Object.keys(localStorage).filter(key =>
		key.includes('CognitoIdentityServiceProvider')
	);

	const clearOnLogout = [
		TOKEN,
		RECENT_SEARCH,
		CURRENT_ACCOUNT_ID,
		REFRESH_TOKEN,
		COGNITO_CONFIG,
		SEARCH_FILTERS,
		SELECTED_MAP_LAYERS,
		SELECTED_MAP_STYLE,
		TABLE_SETTINGS,
		INTENDED_LOCATION,
		LIVE_TRAFFIC_COLUMNS,
		SELECTED_SEARCH_TYPE,
		AIRSPACE_MAP_BOUNDS,
		AIRSPACE_MAP_PIN,
		...cognitoItemKeys
	];

	for (const key of clearOnLogout) {
		if (localStorage.getItem(key)) {
			localStorage.removeItem(key);
		}
	}
};

export const amplifySignIn =
	({ username, password, configObj, navigate, queryParams }) =>
	async dispatch => {
		let amplifyConfig = createAmplifyConfig(configObj);
		Amplify.configure(amplifyConfig);
		try {
			return await Auth.signIn(username, password, {
				language: localStorage.getItem(SELECTED_LANGUAGE)
			});
		} catch (error) {
			dispatch(setAmplifyError(true));
			//Overwrite the following errors with custom messages:
			//e.message === 'UserMigration failed with error User does not exist.'
			//e.message === 'UserMigration failed with error Login credentials are incorrect.'
			//e.code === 'UserNotFoundException'
			//e.code === 'UserNotConfirmedException'

			//All other errors return text from Cognito
			if (
				error.message === 'UserMigration failed with error Login credentials are incorrect.' ||
				error.message === 'UserMigration failed with error User does not exist.' ||
				error.code === 'UserNotFoundException'
			) {
				dispatch(
					addSnackbarAlerts([
						{
							type: 'error',
							message: `${t('Incorrect username or password')}.`
						}
					])
				);
			} else if (error.code === 'UserNotConfirmedException') {
				dispatch(
					addSnackbarAlerts([
						{
							type: 'error',
							message: `${t('User not confirmed, resending verification code')}.`
						}
					])
				);
				dispatch(amplifyResendCode(username));
				//Pass along any query params that were sent by mobile app
				const updatedQueryParameters = {
					...queryParams,
					email: username //Pass along the email that was used to sign up
				};
				navigate({
					pathname: '/confirm-signup',
					search: stringifyQueryObj(updatedQueryParameters)
				});
			} else {
				//All other errors return text from Cognito
				dispatch(addSnackbarAlerts([{ type: 'error', message: error.message }]));
			}
		}
	};

export const amplifySignUp =
	({ signupPayload, queryParams, navigate }) =>
	async dispatch => {
		const { firstname, lastname, email, password } = signupPayload;
		const newDomainValues = await dispatch(fetchCognitoConfig(email));

		if (newDomainValues) {
			const cognitoConfig = createCognitoConfig(newDomainValues);
			let amplifyConfig = createAmplifyConfig(cognitoConfig);
			Amplify.configure(amplifyConfig);
			try {
				const res = await Auth.signUp({
					username: email,
					password,
					attributes: {
						given_name: firstname,
						family_name: lastname
					},
					clientMetadata: {
						language: localStorage.getItem(SELECTED_LANGUAGE)
					}
				});
				dispatch(
					addSnackbarAlerts([
						{
							type: 'success',
							message: `${t("Success! We've sent a six-digit verification code to your email")}. ${t(
								'Please enter that code to proceed'
							)}.`,
							duration: 20_000
						}
					])
				);
				const username = res?.user?.username;
				//Pass along any query params that were sent by mobile app
				const updatedQueryParameters = {
					...queryParams,
					email: username //Pass along the email that was used to sign up
				};
				navigate({
					pathname: '/confirm-signup',
					search: stringifyQueryObj(updatedQueryParameters)
				});
				return res;
			} catch (error) {
				//Overwrite the following errors with custom messages:
				//e.message === 'PreSignUp failed with error User already exists, please sign in.'
				//e.message === 'An account with the given email already exists.'
				//e?.code === 'InvalidParameterException'

				//All other errors return text from Cognito

				handleErrors({
					errors:
						error.message === 'PreSignUp failed with error User already exists, please sign in.' ||
						error.message === 'An account with the given email already exists.'
							? dispatch(
									addSnackbarAlerts([
										{
											type: 'error',
											message: t('An account with this email already exists, please'),
											linkMessage: `${t('sign in')}.`,
											link: '/login'
										}
									])
								)
							: error?.code === 'InvalidParameterException'
								? convertCognitoError({
										...error,
										message: `${t('Please verify all fields are in correct format')}.`
									})
								: convertCognitoError(error), //All other errors return text from Cognito
					dispatch
				});
			}
		}
	};

export const amplifyConfirmSignUp =
	({ username, code, navigate, queryParams }) =>
	async dispatch => {
		const newDomainValues = await dispatch(fetchCognitoConfig(username));
		if (newDomainValues) {
			const cognitoConfig = createCognitoConfig(newDomainValues);
			let amplifyConfig = createAmplifyConfig(cognitoConfig);
			Amplify.configure(amplifyConfig);
			//Must be written using .then/.catch or error will not be caught (can not use async await)
			//No Hub.listen events for this Auth.confirmSignUp method - handle responses here:
			Auth.confirmSignUp(username, code, {
				//q: what is clientMetadata?
				//a: https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html

				clientMetadata: { language: localStorage.getItem(SELECTED_LANGUAGE) }
			})
				.then(res => {
					dispatch(
						addSnackbarAlerts([
							{
								type: 'success',
								message: `${t('Your account was successfully verified! You may now sign in')}.`,
								duration: 20_000
							}
						])
					);
					navigate({
						pathname: '/login',
						search: stringifyQueryObj(queryParams)
					});
					return res;
				})
				.catch(error => {
					//Returns text from Cognito
					handleErrors({ errors: convertCognitoError(error), dispatch });
				});
		}
	};

export const amplifyResendCode = username => async dispatch => {
	const newDomainValues = await dispatch(fetchCognitoConfig(username));
	if (newDomainValues) {
		const cognitoConfig = createCognitoConfig(newDomainValues);
		let amplifyConfig = createAmplifyConfig(cognitoConfig);
		Amplify.configure(amplifyConfig);
		//No Hub.listen events for this Auth.resendSignUp method - handle responses here:
		try {
			const res = await Auth.resendSignUp(username, {
				language: localStorage.getItem(SELECTED_LANGUAGE)
			});
			dispatch(
				addSnackbarAlerts([
					{
						type: 'success',
						message: `${t("Success! We've sent a six-digit verification code to your email")}. ${t(
							'Please enter that code to proceed'
						)}.`,
						duration: 20_000
					}
				])
			);
			return res;
		} catch (error) {
			//Returns text from Cognito
			handleErrors({ errors: convertCognitoError(error), dispatch });
		}
	}
};

export const amplifyForgotPassword =
	({ username, config, navigate, queryParams }) =>
	async dispatch => {
		dispatch(setAmplifyError(null));
		let amplifyConfig = createAmplifyConfig(config);
		Amplify.configure(amplifyConfig);
		//Must write in try/catch or response will not return to where code is called
		try {
			const res = await Auth.forgotPassword(username, {
				language: localStorage.getItem(SELECTED_LANGUAGE)
			});
			dispatch(
				addSnackbarAlerts([
					{
						type: 'success',
						message: `${t("Success! We've sent a six-digit verification code to your email")}. ${t(
							'Please enter that code to proceed'
						)}.`,
						duration: 20_000
					}
				])
			);
			return res;
		} catch (error) {
			dispatch(setAmplifyError(true));

			//Overwrite the following errors with custom messages:
			//e.message === 'UserMigration failed with error User does not exist.'
			//e.code === 'UserNotFoundException'
			//e.code === 'InvalidParameterException'

			//All other errors return text from Cognito

			if (
				error.message === 'UserMigration failed with error User does not exist.' ||
				error.code === 'UserNotFoundException'
			) {
				dispatch(
					addSnackbarAlerts([
						{
							type: 'error',
							message: `${t('Reset password failed')}.`
						}
					])
				);
			} else if (error.code === 'InvalidParameterException') {
				dispatch(amplifyResendCode(username));
				//Pass along any query params that were sent by mobile app
				const updatedQueryParameters = {
					...queryParams,
					email: username //Pass along the email that was used to sign up
				};
				navigate({
					pathname: '/confirm-signup',
					search: stringifyQueryObj(updatedQueryParameters)
				});
			} else {
				//All other errors return text from Cognito
				dispatch(addSnackbarAlerts([{ type: 'error', message: error.message }]));
			}
		}
	};

export const amplifyResetPassword =
	({ username, newPassword, code, config, navigate, queryParams }) =>
	async dispatch => {
		let amplifyConfig = createAmplifyConfig(config);
		Amplify.configure(amplifyConfig);
		//Must write in try/catch or response will not return to where code is called
		try {
			const res = await Auth.forgotPasswordSubmit(username, code, newPassword, {
				language: localStorage.getItem(SELECTED_LANGUAGE)
			});
			dispatch(
				addSnackbarAlerts([
					{
						type: 'success',
						message: `${t('Success! Your password was reset')}.`
					}
				])
			);
			navigate({
				pathname: '/login',
				search: stringifyQueryObj(queryParams)
			});
			return res;
		} catch (error) {
			//Returns text from Cognito
			handleErrors({ errors: convertCognitoError(error), dispatch });
		}
	};

export const amplifyEventListener = () => dispatch => {
	Hub.listen('auth', data => {
		switch (data.payload.event) {
			case 'tokenRefresh_failure':
			case 'forgotPasswordSubmit_failure':
			case 'signUp_failure': {
				dispatch(setAmplifyError(true));
				//Returns text from Cognito
				dispatch(addSnackbarAlerts([{ type: 'error', message: data.payload.data.message }]));
				break;
			}
			default: {
				break;
			}
		}
	});
};

export const checkCanSignUp = email => async dispatch => {
	const response = await dispatch(registrationsPreCheck({ email }));

	const enterprise = response === 0;
	const banned = response === 1;
	const allowed = response === 2;

	const domain = email.match(/@(.*)/)[1];

	if (allowed) {
		return true;
	} else {
		if (banned) {
			dispatch(
				addSnackbarAlerts([
					{
						type: 'error',
						message: `${t('Free account access is not available')}.
					${t('Please contact your UAS administrator or ')} sales@aloft.ai ${t('for more information')}.`
					}
				])
			);
		}
		if (enterprise) {
			dispatch(
				addSnackbarAlerts([
					{
						type: 'error',
						message: `${t('An enterprise account exists for')} ${domain}.
					${t('You should have received an email with a link to join that account')}.
					${t('Please contact your administrator or')} support@aloft.ai ${t('with any questions')}.`
					}
				])
			);
		}
		return false;
	}
};
