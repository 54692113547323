import { createSelector, createSlice } from '@reduxjs/toolkit';
import { filterOperatingPicture, filterTrafficData } from 'components/airSpace/airspaceUtils';
import { mapBoundingBoxSelector } from 'reducers/mapSlice';
import { filterMapFeaturesByBoundingBox } from 'utils/mapUtils';
import { getLiveTrafficUnitsFromLS } from 'actions/localStorage';

const initialState = {
	airMeshFeatures: [],
	airMeshWebsocketConnected: false,
	airMeshConnectionError: false,
	airMeshConnectionLoading: false,
	airMeshPusherInstance: null,
	pauseTrafficUpdates: false,
	airMeshAircraftTypes: [],
	airMeshProviderTypes: [],
	airMeshSignalSourceTypes: [],
	aircraftTypesFilterValue: [],
	providerTypesFilterValue: [],
	operatingPictureFeatures: [],
	operatingPictureTypesFilterValue: [],
	operatingPictureSourcesFilterValue: [],
	signalSourcesFilterValue: [],
	ownshipIDs: [],
	airMeshChannels: [],
	units: getLiveTrafficUnitsFromLS()
};

const liveTrafficSlice = createSlice({
	name: 'liveTraffic',
	initialState,
	reducers: {
		setAMTrafficFeatures(state, action) {
			//This is filtered out in dev tools so that is doesn't clutter the feed (see configureStore.js)
			state.airMeshFeatures = action.payload;
		},
		setAMWebSocketConnected(state, action) {
			state.airMeshWebsocketConnected = action.payload;
		},
		setAMConnectionError(state, action) {
			state.airMeshConnectionError = action.payload;
		},
		setAMConnectionLoading(state, action) {
			state.airMeshConnectionLoading = action.payload;
		},
		setAMPusherInstance(state, action) {
			state.airMeshPusherInstance = action.payload;
		},
		setPauseTrafficUpdates(state, action) {
			state.pauseTrafficUpdates = action.payload;
		},
		setAirMeshAircraftTypes(state, action) {
			state.airMeshAircraftTypes = action.payload;
		},
		setAirMeshProviderTypes(state, action) {
			state.airMeshProviderTypes = action.payload;
		},
		setAircraftTypesFilterValue(state, action) {
			state.aircraftTypesFilterValue = action.payload;
		},
		setProviderTypesFilterValue(state, action) {
			state.providerTypesFilterValue = action.payload;
		},
		setOwnshipIDs(state, action) {
			state.ownshipIDs = action.payload;
		},
		setOperatingPictureFeatures(state, action) {
			state.operatingPictureFeatures = action.payload;
		},
		setOperatingPictureTypesFilterValue(state, action) {
			state.operatingPictureTypesFilterValue = action.payload;
		},
		setOperatingPictureSourcesFilterValue(state, action) {
			state.operatingPictureSourcesFilterValue = action.payload;
		},
		setAirMeshChannels(state, action) {
			state.airMeshChannels = action.payload;
		},
		setTrafficUnits(state, action) {
			state.units = action.payload;
		},
		setAirMeshSignalSourceTypes(state, action) {
			state.airMeshSignalSourceTypes = action.payload;
		},
		setSignalSourcesFilterValue(state, action) {
			state.signalSourcesFilterValue = action.payload;
		},
		resetAMState(state) {
			state.airMeshFeatures = [];
			state.airMeshWebsocketConnected = false;
			state.airMeshConnectionError = false;
			state.airMeshConnectionLoading = false;
			state.airMeshPusherInstance = null;
		},
		resetLiveTrafficSlice() {
			return initialState;
		}
	}
});

export const {
	setAMTrafficFeatures,
	setAMWebSocketConnected,
	setAMConnectionError,
	setAMConnectionLoading,
	setAMPusherInstance,
	resetAMState,
	setPauseTrafficUpdates,
	setAirMeshAircraftTypes,
	setAirMeshProviderTypes,
	setAircraftTypesFilterValue,
	setProviderTypesFilterValue,
	setOwnshipIDs,
	setOperatingPictureFeatures,
	setOperatingPictureTypesFilterValue,
	setAirMeshChannels,
	setOperatingPictureSourcesFilterValue,
	resetLiveTrafficSlice,
	setTrafficUnits,
	setSignalSourcesFilterValue,
	setAirMeshSignalSourceTypes
} = liveTrafficSlice.actions;

export default liveTrafficSlice.reducer;

const airMeshFeaturesSelector = state => state.liveTraffic.airMeshFeatures;

export const aircraftTypesFilterValueSelector = state => state.liveTraffic.aircraftTypesFilterValue;
export const providerTypesFilterValueSelector = state => state.liveTraffic.providerTypesFilterValue;
export const signalSourcesFilterValueSelector = state => state.liveTraffic.signalSourcesFilterValue;

export const trafficFeaturesSelector = createSelector(
	[
		airMeshFeaturesSelector,
		aircraftTypesFilterValueSelector,
		providerTypesFilterValueSelector,
		signalSourcesFilterValueSelector,
		mapBoundingBoxSelector
	],
	(
		airMeshFeatures,
		aircraftTypesFilterValue,
		providerTypesFilterValue,
		signalSourcesFilterValueSelector,
		mapBoundingBox
	) => {
		const filtered = filterTrafficData(
			airMeshFeatures,
			aircraftTypesFilterValue,
			providerTypesFilterValue,
			signalSourcesFilterValueSelector
		);

		if (mapBoundingBox) {
			const filteredByMapBounds = filterMapFeaturesByBoundingBox({
				features: filtered,
				boundingBox: mapBoundingBox
			});
			return filteredByMapBounds;
		} else {
			return filtered;
		}
	}
);
export const airMeshWebsocketConnectedSelector = state =>
	state.liveTraffic.airMeshWebsocketConnected;
export const airMeshConnectionErrorSelector = state => state.liveTraffic.airMeshConnectionError;
export const airMeshConnectionLoadingSelector = state => state.liveTraffic.airMeshConnectionLoading;
export const airMeshPusherInstanceSelector = state => state.liveTraffic.airMeshPusherInstance;
export const pauseTrafficUpdatesSelector = state => state.liveTraffic.pauseTrafficUpdates;
export const airMeshAircraftTypesSelector = state => state.liveTraffic.airMeshAircraftTypes;
export const airMeshProviderTypesSelector = state => state.liveTraffic.airMeshProviderTypes;
export const airMeshSignalSourceTypesSelector = state => state.liveTraffic.airMeshSignalSourceTypes;
export const ownshipsIDsSelector = state => state.liveTraffic.ownshipIDs;
export const operatingPictureFeaturesSelector = state => state.liveTraffic.operatingPictureFeatures;
export const operatingPictureTypesFilterValueSelector = state =>
	state.liveTraffic.operatingPictureTypesFilterValue;
export const operatingPictureSourcesFilterValueSelector = state =>
	state.liveTraffic.operatingPictureSourcesFilterValue;

export const operatingPictureSelector = createSelector(
	[
		operatingPictureFeaturesSelector,
		operatingPictureTypesFilterValueSelector,
		operatingPictureSourcesFilterValueSelector,
		mapBoundingBoxSelector
	],
	(
		operatingPictureFeatures,
		operatingPictureTypesFilterValue,
		operatingPictureSourcesFilterValue,
		mapBoundingBox
	) => {
		const filtered = filterOperatingPicture(
			operatingPictureFeatures,
			operatingPictureTypesFilterValue,
			operatingPictureSourcesFilterValue
		);
		if (mapBoundingBox) {
			const filteredByMapBounds = filterMapFeaturesByBoundingBox({
				features: filtered,
				boundingBox: mapBoundingBox
			});
			return filteredByMapBounds;
		} else {
			return filtered;
		}
	}
);
export const airMeshChannelsSelector = state => state.liveTraffic.airMeshChannels;
export const trafficUnitsSelector = state => state.liveTraffic.units;
