import { createSlice } from '@reduxjs/toolkit';
import {
	getSelectedMapSourcesFromLS,
	getSelectedMapStyleFromLS,
	getSelectedMapBoxStyleFromLS,
	getAirspaceMapBoundsFromLS,
	getAirspaceMapPinFromLS
} from 'actions/localStorage';
import { emptyFeatureCollection } from 'utils/mapUtils';

const computeInitialState = () => ({
	mapLoading: false,
	userCoordinate: null,
	markerDetails: null,
	loading: false, //Mapbox component
	userLocationLoading: false, //Browser geolocation
	locationDetailsLoading: false, //Mapbox api calls
	style: getSelectedMapStyleFromLS(),
	styleOptions: [],
	mapboxSources: [],
	aloftSources: [],
	visibleMapboxSources: getSelectedMapSourcesFromLS(),
	tileServerLayers: [],
	visibleMapLayers: [],
	sources: [],
	styleConfig: null,
	weatherLoading: false,
	weather: null,
	advisoriesLoading: false,
	advisory: null,
	geolocationError: null,
	featureCollection: emptyFeatureCollection,
	updateMapFromFile: false,
	smallDrawInstance: null,
	largeDrawInstance: null,
	selectedFeatures: [],
	radiusObject: {},
	unauthenticatedStyle: getSelectedMapBoxStyleFromLS(),
	mapReadyForDrawing: false,
	clickedFeatures: [],
	searchOptions: [],
	mapBoundingBox: null, //Used to track the bounds of the map as it is moved around for live traffic display
	airspaceMapBounds: getAirspaceMapBoundsFromLS(), //Used to save the user's view when they leave the map
	airspaceMapPin: getAirspaceMapPinFromLS(),
	renderMap: true,
	showFeatureCard: false,
	mapPinInView: true
});

const mapSlice = createSlice({
	name: 'map',
	initialState: computeInitialState(),
	reducers: {
		setMapLoading(state, action) {
			state.mapLoading = action.payload;
		},
		setUserCoordinate(state, action) {
			state.userCoordinate = action.payload;
		},
		setMarkerDetails(state, action) {
			state.markerDetails = action.payload;
		},
		setUserLocationLoading(state, action) {
			state.userLocationLoading = action.payload;
		},
		setLocationDetailsLoading(state, action) {
			state.locationDetailsLoading = action.payload;
		},
		setMapStyle(state, action) {
			state.style = action.payload;
		},
		setMapStyleConfig(state, action) {
			state.styleConfig = action.payload;
		},
		setWeatherLoading(state, action) {
			state.weatherLoading = action.payload;
		},
		setWeather(state, action) {
			state.weather = action.payload;
		},
		setAdvisoriesLoading(state, action) {
			state.advisoriesLoading = action.payload;
		},
		setAdvisoryObject(state, action) {
			state.advisory = action.payload;
		},
		setGeolocationError(state, action) {
			state.geolocationError = action.payload;
		},
		setFeatureCollection(state, action) {
			state.featureCollection = action.payload;
		},
		clearFeatureCollection(state) {
			state.featureCollection = emptyFeatureCollection;
		},
		clearPolygonFeatures(state) {
			state.featureCollection = {
				...state.featureCollection,
				features: state.featureCollection.features.filter(
					element => element.geometry.type !== 'Polygon'
				)
			};
		},
		setUpdateMapFromFile(state, action) {
			state.updateMapFromFile = action.payload;
		},
		setSelectedFeatures(state, action) {
			state.selectedFeatures = action.payload;
		},
		setRadiusObject(state, action) {
			state.radiusObject = {
				...state.radiusObject,
				...action.payload
			};
		},
		setUnauthenticatedStyle(state, action) {
			state.unauthenticatedStyle = action.payload;
		},
		setMapSources(state, action) {
			state.sources = action.payload;
		},
		setVisibleMapLayers(state, action) {
			state.visibleMapLayers = action.payload;
		},
		setMapStyleOptions(state, action) {
			state.styleOptions = action.payload;
		},
		setTileServerLayers(state, action) {
			state.tileServerLayers = action.payload;
		},
		setMapboxSources(state, action) {
			state.mapboxSources = action.payload;
		},
		setTileServerSources(state, action) {
			state.tileServerSources = action.payload;
		},
		setVisibleMapboxSources(state, action) {
			state.visibleMapboxSources = action.payload;
		},
		setMapReadyForDrawing(state, action) {
			state.mapReadyForDrawing = action.payload;
		},
		setAloftSources(state, action) {
			state.aloftSources = action.payload;
		},
		setMapClickedFeatures(state, action) {
			state.clickedFeatures = action.payload;
		},
		setSearchOptions(state, action) {
			state.searchOptions = action.payload;
		},
		setMapBoundingBox(state, action) {
			state.mapBoundingBox = action.payload;
		},
		setSmallDrawInstance(state, action) {
			state.smallDrawInstance = action.payload;
		},
		setLargeDrawInstance(state, action) {
			state.largeDrawInstance = action.payload;
		},
		setRenderMap(state, action) {
			state.renderMap = action.payload;
		},
		setShowFeatureCard(state, action) {
			state.showFeatureCard = action.payload;
		},
		setMapPinInView(state, action) {
			state.mapPinInView = action.payload;
		},
		setAirspaceMapBounds(state, action) {
			state.airspaceMapBounds = action.payload;
		},
		setAirspaceMapPin(state, action) {
			state.airspaceMapPin = action.payload;
		},
		resetMapSlice() {
			return computeInitialState();
		}
	}
});

export const {
	setMapLoading,
	setUserCoordinate,
	setMarkerDetails,
	setUserLocationLoading,
	setLocationDetailsLoading,
	setMapStyle,
	setMapStyleConfig,
	setWeatherLoading,
	setWeather,
	setAdvisoriesLoading,
	setAdvisoryObject,
	setGeolocationError,
	setFeatureCollection,
	clearPolygonFeatures,
	setUpdateMapFromFile,
	setSelectedFeatures,
	setRadiusObject,
	clearFeatureCollection,
	setMapSources,
	setVisibleMapLayers,
	setMapStyleOptions,
	setTileServerLayers,
	setMapboxSources,
	setTileServerSources,
	setVisibleMapboxSources,
	setMapReadyForDrawing,
	setAloftSources,
	resetMapSlice,
	setUnauthenticatedStyle,
	setMapClickedFeatures,
	setSearchOptions,
	setMapBoundingBox,
	setLargeDrawInstance,
	setSmallDrawInstance,
	setRenderMap,
	setShowFeatureCard,
	setMapPinInView,
	setAirspaceMapBounds,
	setAirspaceMapPin
} = mapSlice.actions;

export default mapSlice.reducer;

export const mapLoadingSelector = state => state.map.mapLoading;
export const userCoordinateSelector = state => state.map.userCoordinate;
export const markerDetailsSelector = state => state.map.markerDetails;
export const userLocationLoadingSelector = state => state.map.userLocationLoading;
export const locationDetailsLoadingSelector = state => state.map.locationDetailsLoading;
export const mapSelectedLayersSelector = state => state.map.selectedLayers;

export const mapStyleSelector = state => state.map.style;
export const mapStyleOptionsSelector = state => state.map.styleOptions;
export const mapStyleConfigSelector = state => state.map.styleConfig;
export const weatherLoadingSelector = state => state.map.weatherLoading;
export const weatherSelector = state => state.map.weather;
export const advisoriesLoadingSelector = state => state.map.advisoriesLoading;
export const advisoryObjectSelector = state => state.map.advisory;
export const geolocationErrorSelector = state => state.map.geolocationError;
export const featureCollectionSelector = state => state.map.featureCollection;
export const updateMapFromFileSelector = state => state.map.updateMapFromFile;
export const smallDrawInstanceSelector = state => state.map.smallDrawInstance;
export const largeDrawInstanceSelector = state => state.map.largeDrawInstance;
export const selectedFeaturesSelector = state => state.map.selectedFeatures;
export const radiusObjectSelector = state => state.map.radiusObject;
export const visibleMapLayersSelector = state => state.map.visibleMapLayers;
export const tileServerLayersSelector = state => state.map.tileServerLayers;
export const mapboxSourcesSelector = state => state.map.mapboxSources;
export const tileServerSourcesSelector = state => state.map.tileServerSources;
export const mapSourcesSelector = state => state.map.sources;
export const visibleMapboxSourcesSelector = state => state.map.visibleMapboxSources;
export const mapReadyForDrawingSelector = state => state.map.mapReadyForDrawing;
export const aloftSourcesSelector = state => state.map.aloftSources;
export const mapUnauthenticatedStyleSelector = state => state.map.unauthenticatedStyle;
export const mapClickedFeaturesSelector = state => state.map.clickedFeatures;
export const mapSearchOptionsSelector = state => state.map.searchOptions;
export const mapBoundingBoxSelector = state => state.map.mapBoundingBox;
export const renderMapSelector = state => state.map.renderMap;
export const showFeatureCardSelector = state => state.map.showFeatureCard;
export const mapPinInViewSelector = state => state.map.mapPinInView;
export const airspaceMapBoundsSelector = state => state.map.airspaceMapBounds;
export const airspaceMapPinSelector = state => state.map.airspaceMapPin;
