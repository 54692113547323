export const BASE_JWT_URL = process.env.REACT_APP_JWT_BASE_URL;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

// Mapbox
export const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// Google Tag
export const GOOGLE_TAG_ID = process.env.REACT_APP_GOOGLE_TAG_ID;

// AWS Cognito
export const COGNITO_REDIRECT_URI = process.env.REACT_APP_COGNITO_REDIRECT_URI;
export const COGNITO_LOGOUT_URI = process.env.REACT_APP_COGNITO_LOGOUT_URI;

//Material UI
export const MUI_KEY = process.env.REACT_APP_MUI_KEY;

//Intercom
export const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID;

//Hidden Level
export const HIDDEN_LEVEL_API_KEY = process.env.REACT_APP_HIDDEN_LEVEL_API_KEY;

//Pusher
export const AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY =
	process.env.REACT_APP_AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY;
export const AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER =
	process.env.REACT_APP_AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER;

//Drone Tag
export const DRONETAG_PUBLIC_HOST = process.env.REACT_APP_DRONETAG_PUBLIC_HOST;
