import { COGNITO_REDIRECT_URI } from 'constants/environmentVariables';
import { endsWith } from 'lodash-es';
import { t } from 'i18next';

export const createMobileRedirectUrl = ({
	host,
	client_id,
	success_redirect_uri,
	refresh_token,
	id_token,
	access_token
}) => {
	const refreshEndpoint = `${host}/oauth2/token?grant_type=refresh_token&client_id=${client_id}&redirect_uri=${success_redirect_uri}&refresh_token=${refresh_token}`;
	const logoutEndpoint = `${host}/logout?client_id=${client_id}`;
	const url = `${success_redirect_uri}?id_token=${id_token}&refresh_token=${refresh_token}&access_token=${access_token}&refresh_endpoint=${refreshEndpoint}&logout_endpoint=${encodeURIComponent(
		logoutEndpoint
	)}`;
	return url;
};

export const createAdminPageRedirect = ({
	host,
	client_id,
	success_redirect_uri,
	id_token,
	refresh_token
}) => {
	const logoutEndpoint = `${host}/logout?client_id=${client_id}`;
	const url = `${success_redirect_uri}?id_token=${id_token}&refresh_token=${refresh_token}&logout_endpoint=${encodeURIComponent(
		logoutEndpoint
	)}`;
	return url;
};

export const createAmplifyConfig = configObject => {
	const { client_id, pool } = configObject;

	return {
		Auth: {
			// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
			// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

			// REQUIRED - Amazon Cognito Region
			region: 'us-east-2',

			// // OPTIONAL - Amazon Cognito Federated Identity Pool Region
			// // Required only if it's different from Amazon Cognito Region
			// identityPoolRegion: 'XX-XXXX-X',

			// OPTIONAL - Amazon Cognito User Pool ID
			userPoolId: pool,

			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolWebClientId: client_id,

			// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
			mandatorySignIn: false,

			// OPTIONAL - Configuration for cookie storage
			// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
			// cookieStorage: {
			//     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
			//     domain: 'http://localhost:3000',
			//     // OPTIONAL - Cookie path
			//     path: '/',
			//     // OPTIONAL - Cookie expiration in days
			//     expires: 365,
			//     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
			//     sameSite: "strict", // | "lax",
			//     // OPTIONAL - Cookie secure flag
			//     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
			//     secure: true
			// },

			// OPTIONAL - customized storage object
			// storage: MyStorage,

			// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
			authenticationFlowType: 'USER_PASSWORD_AUTH',

			// // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
			// clientMetadata: { myCustomKey: 'myCustomValue' },

			// OPTIONAL - Hosted UI configuration

			//This is not being used in our app
			oauth: {
				domain: 'auth.kittyspacehawk.com',
				scope: ['openid'], // ,[ 'phone', 'email', 'profile', 'aws.cognito.signin.user.admin'],
				redirectSignIn: 'http://localhost:3000/',
				redirectSignOut: 'http://localhost:3000/',
				responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
			}
		}
	};
};

export const parseLoginError = error => {
	switch (error) {
		case 'access_denied': {
			return `${t('Access is denied')}.`;
		}
		default: {
			return error;
		}
	}
};

export const convertCognitoError = error => {
	const { code, message } = error;
	return {
		response: {
			data: {
				errors: [{ detail: message, code }]
			}
		}
	};
};

export const checkRedirectURIAllowed = redirect_uri => {
	if (redirect_uri.startsWith('aircontrol:')) {
		//Mobile app link
		return true;
	}
	try {
		const allowedProtocols = ['http:', 'https:'];
		const allowedHostnameEndings = ['aloft.ai', 'aloft.rocks', 'localhost', 'aloft.link'];
		const { protocol, hostname } = new URL(redirect_uri);
		const protocolAllowed = allowedProtocols.includes(protocol);
		//Check that one of the allowedHostnameEndings matches the ending of the hostname
		const hostnameAllowed = allowedHostnameEndings.some(allowed => endsWith(hostname, allowed));
		return protocolAllowed && hostnameAllowed;
	} catch {
		//redirect_uri is not a valid URL
		return false;
	}
};

export const createCognitoConfig = (domainValues, success_redirect_uri) => {
	return {
		client_id: domainValues.client_id,
		host: domainValues.host,
		pool: domainValues.pool,
		redirect_uri: COGNITO_REDIRECT_URI,
		success_redirect_uri
	};
};
