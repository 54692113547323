import { clearLocalStorageOnLogout } from 'actions/authentication';
import { REFRESH_TOKEN } from 'constants/localStorageConstants';
import { useIntercom } from 'react-use-intercom';
import { logout } from 'apis/authentication.api';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const Logout = () => {
	const { hardShutdown } = useIntercom();
	const dispatch = useDispatch();
	const refreshToken = localStorage.getItem(REFRESH_TOKEN);

	useEffect(() => {
		const performLogout = async () => {
			await dispatch(logout(refreshToken));
			hardShutdown();
			clearLocalStorageOnLogout();
			window.location.assign(`${origin}/login`);
		};

		performLogout();
	}, [dispatch, hardShutdown, refreshToken]);

	return null;
};

export default Logout;
